import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch';
import Cookies from 'js-cookie'

export const client = new ApolloClient({
  uri: process.env.GATSBY_APOLLO_API,
  fetch,
  request: (operation) => {
    const token = Cookies.get('authToken')
    console.log('token', token)
    operation.setContext({
      headers: {
        authorization: token || ''
      }
    })
  }
});
